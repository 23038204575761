
@font-face {
    font-family: WSupremeRegular;
    src: url("./assets/fonts/westin/WSupreme-Regular.woff");
    font-stretch: normal;
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: WSupremeMedium;
    src: url("./assets/fonts/westin/WSupreme-Medium.woff");
    font-stretch: normal;
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: WSupremeItalic;
    src: url("./assets/fonts/westin/WSupreme-Italic.woff");
    font-stretch: normal;
    font-weight: 300;
    font-style: italic;
}
 
@font-face {
    font-family: WSupremeMediumItalic;
    src: url("./assets/fonts/westin/WSupreme-MediumItalic.woff");
    font-stretch: normal;
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: WSupremeSemiBold;
    src: url("./assets/fonts/westin/WSupreme-SemiBold.woff");
    font-stretch: normal;
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: WSupremeSemiBoldItalic;
    src: url("./assets/fonts/westin/WSupreme-SemiBoldItalic.woff");
    font-stretch: normal;
    font-weight: 600;
    font-style: italic;
}
b{
    font-family: WSupremeSemiBold;
}
body {
    margin: 0;
    font-family: WSupremeRegular;
    margin: 0;
    padding: 0;
    background-color: #fff;
    -webkit-overflow-scrolling: touch;
    background-image: url('./assets/07.png');
    background-size: contain;
    background-position: 50%;
    background-repeat: repeat;
    font-size: 1.4rem;
}
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.App {
    max-width: 500px;
    margin: auto;
}

.center-top {
    justify-content: flex-start !important;
    align-items: center !important;
}
.center {
    justify-content: center !important;
    align-items: center !important;
}
.center-bottom {
    justify-content: flex-end !important;
    align-items: center !important;
}
.loading{
    font-size: 1rem;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

}