.template-container.gold {
    height: 100vh;
    .template-hero-banner {
        background-image: url(../../assets/bannerHome.jpg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 2rem;
        text-align: center;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 60vh;
        background-position: center center;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.05);
        }
        .title-welcome {
            font-weight: 500;
            font-size: 2rem;
            color: #fff;
            line-height: 1.2;
            position: relative;
        }
        .guest-name {
            font-weight: 500;
            font-size: 1.1rem;
            color: #fff;
            position: relative;
            width: 65%;
            padding: 0 1rem;
            margin: 0.5rem 0 0.75rem;
            line-height: 1.5;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-bottom: 1px solid #fff;
            }
        }
        .welcome-msg {
            font-weight: 400;
            font-size: 0.6rem;
            color: #fff;
            position: relative;
            width: 100%;
            max-width: 80%;
            line-height: 1.5;
            font-family: WSupremeRegular;
        }
    }

    .template-sponsers {
        min-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-image: url(../../assets/m-logo-shape.svg);
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: bottom left;
        .tagline {
            color: #000;
            position: relative;
            font-size: 0.6rem;
            margin: 0.5rem 0 0;
            font-style: italic;
            margin-bottom: 0.5rem;
            text-transform: capitalize;
        }
        .sponsers-logo {
            background-image: url('../../assets/bonvoy.png');
            background-size: cover;
            background-repeat: no-repeat;
            text-align: center;
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 10rem;
            height: 3rem;
            background-size: cover;
        }
        .subtitle {
            color: #000;
            position: relative;
            font-size: 0.35rem;
            margin: 0.5rem 0 0;
            text-transform: uppercase;
            font-weight: 700;
        }
        .app-links {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            margin: 0.5rem 0 0;

            .app-store,
            .play-store {
                background-image: url('../../assets/app-store.png');
                background-size: cover;
                background-repeat: no-repeat;
                text-align: center;
                position: relative;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 4rem;
                height: 1.2rem;
                background-size: contain;
            }
            .play-store {
                background-image: url('../../assets/play-store.png');
            }
        }
    }
}
