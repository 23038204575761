.notfound-wrapper{
    
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    position: relative;
    justify-content: center;
    padding: 1rem;
    .notfound-image{
        background-image: url(../assets/not-found.svg);
        background-size: contain;
        background-repeat: no-repeat;
        text-align: center;
        position: relative;
        z-index: 1;
        background-position: center center;
        width: 10rem;
        height: 10rem;
        background-color: #fff;
    }
    .error-msg{
        font-size: 13px;
        text-align: center;
        color: #7e7e7e;
        margin-bottom: 1rem;
    }
    .scan-error{
        font-size: 13px;
        text-align: center;
        color: #7e7e7e;
        margin-bottom: 1rem;
    }

}